import { createContext, useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import { io } from 'socket.io-client'
import AuthService from '../Util/AuthService'

export const FunctionsContext = createContext()

// eslint-disable-next-line react/prop-types
export function FunctionsProvider({ children }) {
  const service = new AuthService()
  let savedOfflineConfig = JSON.parse(localStorage.getItem(service.getOfflineConfigStorageKey())) || null
  let savedOnlineConfig = JSON.parse(localStorage.getItem(service.getOnlineConfigStorageKey())) || null

  const { enqueueSnackbar } = useSnackbar()
  const [numeroComanda, setNumeroComanda] = useState('')
  const [situacao, setSituacao] = useState('')
  const [conectado, setContectado] = useState(null)
  const [version, setVersion] = useState({})
  const [socket, setSocket] = useState(null)

  const DEFAULT_OFFLINE_CONFIG = {
    host: savedOfflineConfig?.host || '',
    user: savedOfflineConfig?.user || '',
    password: savedOfflineConfig?.password || '',
    database: savedOfflineConfig?.database || '',
    path: savedOfflineConfig?.path || service.getDefaultPath(),
    port: savedOfflineConfig?.port ? parseInt(savedOfflineConfig?.port) : '',
    time: savedOfflineConfig?.time ? parseInt(savedOfflineConfig?.time) : '',
  }

  const [config, setConfig] = useState(DEFAULT_OFFLINE_CONFIG)

  const DEFAULT_ONLINE_CONFIG = {
    username: savedOnlineConfig?.username || '',
    password: savedOnlineConfig?.password || '',
    path: savedOnlineConfig?.path || service.getDefaultPath(),
    profile: savedOnlineConfig?.profile || null,
  }

  const [onlineConfig, setOnlineConfig] = useState(DEFAULT_ONLINE_CONFIG)

  const palette = {
    primary: {
      main: '#f10b5c',
    },
    secondary: {
      main: '#757575',
    },
    success: {
      main: "#66bb6a",
      light: "#81c784",
      dark: "#388e3c",
    },
    error: {
      main: "#f44336",
      light: "#e57373",
      dark: "#d32f2f",
    }
  }

  useEffect(() => {
    function getQuery() {
      const room = onlineConfig.profile.unidade.uuid + process.env.REACT_APP_SOCKET_ROOM

      return 'room=' + room + '&auth=' + process.env.REACT_APP_WEBSOCKET_AUTH_TOKEN
    }

    function initSocket() {
      if (socket && !onlineConfig.profile) {
        socket.disconnect()
        return
      }

      if ((socket && socket.connected) || !onlineConfig.profile) return

      const webSocket = io(process.env.REACT_APP_SOCKET_URL, {
        query: getQuery(),
        transports: ['websocket'],
      })

      console.log('Socket instanciado', webSocket)

      webSocket.on('connect', async () => {
        setContectado(true)
        console.log('connect SocketIO', webSocket.id)
      })

      webSocket.on('disconnect', async (msg) => {
        console.log('disconnect SocketIO', webSocket.id)
        console.log("Disconnection message", msg);
        setContectado(false)
      })

      setSocket(webSocket)
    }

    initSocket()

    return () => {
      if (socket) {
        socket.disconnect()
      }
    }
  }, [onlineConfig])

  useEffect(() => {
    if (!socket) return

    socket.room = onlineConfig.profile.unidade.uuid + process.env.REACT_APP_SOCKET_ROOM

    // Recebe um update de socket de outros usuários socket
    socket.on('Update', (event) => onMessageReceive(event))

    // Recebe um update do socket do servidor
    socket.on('reciveSocketMessage', (event) => onMessageReceive(event))
  }, [socket])

  async function createXml(element) {
    const xml = await window.ControleComanda.convertElementForXml(null, element)
    const path = config.path || service.getDefaultPath()

    await window.ControleComanda.createFileToPath(null, xml, element, path)
  }

  function resetOfflineConfig() {
    savedOfflineConfig = null
    localStorage.removeItem(service.getOfflineConfigStorageKey())

    setConfig({
      host: '',
      user: '',
      port: '',
      time: '',
      password: '',
      database: '',
      path: service.getDefaultPath(),
    })
  }

  function resetOnlineConfig() {
    savedOnlineConfig = null
    localStorage.removeItem(service.getOnlineConfigStorageKey())

    setOnlineConfig({
      username: '',
      password: '',
      path: service.getDefaultPath(),
      profile: null,
    })
  }

  function isValidOrder(order) {
    if (!order) {
      return false
    }

    return order.tipo === 'COMANDA' || order.tipo === 'MESA'
  }

  function isOrderOpen(order) {
    return order.ativo && (!!order.dataCancelamento || order.pago)
  }

  function handleCatracaXml(aberto, identificador) {
    logsComandasCatraca(aberto, identificador)
    createXml({ aberto, identificador, })
  }

  function onMessageReceive({ type, newOrder, order }) {
    console.log('socket event recebido - type -> ', type)
    console.log('order', newOrder || order)

    if (!isValidOrder(newOrder || order)) return

    if (type === 'ADD_OR_UPDATE_GENERIC_FROM_ORDER') {
      const aberto = isOrderOpen(newOrder)
      handleCatracaXml(aberto ? 'L' : 'C', newOrder.identificadorPrincipal)
    } else if (type === 'REMOVE_ORDER_GENERIC') {
      handleCatracaXml('L', order.identificadorPrincipal)
    }
  }

  async function confirmarComanda() {
    if (!numeroComanda) {
      alert('Informe o número da comanda')
      return
    }

    const element = { identificador: numeroComanda, aberto: situacao }
    const xml = await window.ControleComanda.convertElementForXml(null, element)
    const path = config.path || service.getDefaultPath()

    await window.ControleComanda.createFileToPath(null, xml, element, path)
    logsComandasCatraca(situacao)

    setSituacao('')
    setNumeroComanda('')
    enqueueSnackbar('Situação da comanda registrada com sucesso', { variant: 'success' })
  }

  async function logsComandasCatraca(situacao, numeroComanda) {

    if (!onlineConfig.username) { return }

    const situacoes = {
      'C': `Comanda ${numeroComanda} - aguardando pagamento. Catraca bloqueada (cliente não pode sair)`,
      'L': `Comanda ${numeroComanda} - paga. Catraca Desbloqueada (cliente pode sair)`
    }

    const mensagem = situacoes[situacao]
    const logMessage = `${mensagem}`;

    await window.logger.logInfo(logMessage)
    console.log(logMessage)
  }

  async function changeBuscaOnline() {
    if (!onlineConfig.profile) return false

    return service.get('/api-v2/pedidoIntegracao/getIdentifierStatus').then(data => {
      data.forEach(item => {
        if (item.tipo === 'MESA') return

        createXml({
          identificador: item.identificador,
          aberto: item.aberto ? 'L' : 'C',
        })
      })

      return true
    }).catch(console.log)
  }

  async function changeBusca() {
    const host = config?.host || 'localhost'
    const user = config?.user || 'postgres'
    const database = config?.database || 'sischef'
    const password = config?.password || 'postgres'
    const path = config?.path || service.getDefaultPath()
    const port = config?.port ? parseInt(config?.port) : 5432
    const time = config?.time ? parseInt(config?.time) : 10
    const newConfig = {
      host,
      user,
      password,
      database,
      port,
      path,
      time,
    }

    const alert = await window.ControleComanda.connectDb(null, true, newConfig)
    setContectado(alert)

    if (alert) {
      enqueueSnackbar('Banco conectado', { variant: 'success' })
      return
    }

    enqueueSnackbar('Verifique a conexão com o banco', { variant: 'error' })
  }

  async function getVersionApp() {
    const versionCopy = await window.ControleComanda.getVersion()
    setVersion(versionCopy)
  }

  useEffect(() => {
    async function fetch() {
      const loadOnline = await changeBuscaOnline()

      if (!loadOnline) {
        await changeBusca()
      }

      getVersionApp()
    }

    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <FunctionsContext.Provider value={{
    config,
    setConfig,
    onlineConfig,
    setOnlineConfig,
    resetOfflineConfig,
    resetOnlineConfig,
    numeroComanda,
    setNumeroComanda,
    situacao,
    setSituacao,
    confirmarComanda,
    setContectado,
    changeBusca,
    changeBuscaOnline,
    conectado,
    palette,
    version
  }}>
    {children}
  </FunctionsContext.Provider>
}